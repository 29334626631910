import * as chartConfig from '@/components/Charts/chart.config'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import LineChart from '@/components/Charts/LineChart'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    LineChart,
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar
  }
})
export default class home extends Vue {
  defaultChart: any = {
    chartData: null,
    extraOptions: chartConfig.chartOptionsMain
  }
  get titleStack() {
    return [
      'Admin',
      'Dashboard'
    ]
  }

  mounted() {
    this.fillChartData()

    this.$buefy.snackbar.open({
      message: 'Welcome back',
      queue: false
    })
  }
  randomChartData(n: number) {
    const data: number[] = []

    for (let i = 0; i < n; i++) {
      data.push(Math.round(Math.random() * 200))
    }

    return data
  }
  fillChartData() {
    this.defaultChart.chartData = {
      datasets: [
        {
          fill: false,
          borderColor: chartConfig.chartColors.default.primary,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: chartConfig.chartColors.default.primary,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: chartConfig.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: this.randomChartData(9)
        },
        {
          fill: false,
          borderColor: chartConfig.chartColors.default.info,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: chartConfig.chartColors.default.info,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: chartConfig.chartColors.default.info,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: this.randomChartData(9)
        },
        {
          fill: false,
          borderColor: chartConfig.chartColors.default.danger,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: chartConfig.chartColors.default.danger,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: chartConfig.chartColors.default.danger,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: this.randomChartData(9)
        }
      ],
      labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09']
    }
  }

  render() {
    return <div>
      <title-bar titleStack={this.titleStack} />
      <hero-bar hasRightVisible={false}>Dashboard</hero-bar>
      <section class="section is-main-section">
        <tiles>
          <cardWidget class="tile is-child" type="is-primary" icon="account-multiple" number={512} label="Клијенти" title="test" />
          <cardWidget class="tile is-child" type="is-info" icon="file-document-outline" number={7770} prefix="$" label="Фактурисано" />
          <cardWidget class="tile is-child" type="is-success" icon="chart-timeline-variant" number={256} suffix="%" label="Успешност" />
        </tiles>

        <cardComponent title="Performance" headerIconClick={this.fillChartData} icon="finance" header-icon="reload">
          {
            this.defaultChart.chartData &&
            <div class="chart-area">
              <line-chart style="height: 100%"
                ref="bigChart"
                chartId="big-line-chart"
                chartData={this.defaultChart.chartData}
                extraOptions={this.defaultChart.extraOptions}>
              </line-chart>
            </div>
          }
        </cardComponent>

        <cardComponent title="Клијенти" class="has-table has-mobile-sort-spaced" />
      </section>
    </div>
  }
}
