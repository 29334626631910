import Vue from 'vue';
import Vuex from 'vuex';
import { UserModule } from '@/stores/userModule';
import { UIModule } from '@/stores/uiModule';

Vue.use(Vuex);
const store = new Vuex.Store({});

export const userStore = new UserModule({ store, name: "user" });
export const uiStore = new UIModule({ store, name: "ui" });
