import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AsideMenuItem extends Vue {
  @Prop({ default: null }) item: any;
  isDropdownActive = false;

  get componentIs() {
    return this.item.to ? 'router-link' : 'a'
  }

  get hasDropdown() {
    return !!this.item.menu
  }

  get dropdownIcon() {
    return (this.isDropdownActive) ? 'minus' : 'plus'
  }

  get itemTo() {
    return this.item.to ? this.item.to : null
  }

  get itemHref() {
    return this.item.href ? this.item.href : null
  }

  menuClick() {
    this.$emit('menu-click', this.item)

    if (this.hasDropdown) {
      this.isDropdownActive = (!this.isDropdownActive)
    }
  }

  render() {
    const DynamicComponent = this.componentIs;
    return <li class={{ 'is-active': this.isDropdownActive }}>
      <DynamicComponent
        to={this.itemTo}
        href={this.itemHref}
        onClick={this.menuClick}
        exact-active-class="is-active"
        class={{ 'has-icon': !!this.item.icon, 'has-dropdown-icon': this.hasDropdown }}>
        {this.item.icon && <bIcon icon={this.item.icon} class={{ 'has-update-mark': this.item.updateMark }} customSize="default" />}
        {this.item.label && <span class={{ 'menu-item-label': !!this.item.icon }}> {this.item.label}</span>}
        {this.hasDropdown && <div class="dropdown-icon"><bIcon icon={this.dropdownIcon} custom-size="default" /></div>}
      </DynamicComponent>
      {this.hasDropdown && <aside-menu-list menu={this.item.menu} isSubmenuList={true} />}
    </li >;
  }
}
