import { Vue, Component } from 'vue-property-decorator'
import { modifiers as m } from "vue-tsx-support";
import { userStore, uiStore } from '@/store';
import NavBarMenu from '@/components/NavBarMenu';

@Component
export default class NavBar extends Vue {
  isMenuNavBarActive = false;

  get menuNavBarToggleIcon() {
    return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
  }

  get menuToggleMobileIcon() {
    return uiStore.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
  }

  menuNavBarToggle() {
    this.isMenuNavBarActive = (!this.isMenuNavBarActive)
  }

  logout() {
    userStore.logout()
    this.$buefy.snackbar.open({
      message: 'Log out clicked',
      queue: false
    })
  }

  render() {
    return <nav id="navbar-main" class="navbar">
      <div class="navbar-brand">
        <a
          class="navbar-item is-hidden-desktop"
          onClick={m.prevent(() => uiStore.asideMobileStateToggle())}
        >
          <bIcon icon={this.menuToggleMobileIcon} />
        </a>
        <div class="navbar-item">
          <div class="control">
            <input class="input" placeholder="Search everywhere..." />
          </div>
        </div>
      </div>
      <div class="navbar-brand is-right">
        <a
          class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
          onClick={m.prevent(this.menuNavBarToggle)}
        >
          <bIcon icon={this.menuNavBarToggleIcon} custom-size="default" />
        </a>
      </div>
      <div
        class={{ 'navbar-menu fadeIn animated faster': true, 'is-active': this.isMenuNavBarActive }}
      >
        <div class="navbar-end">
          <NavBarMenu />
          <a
            href="https://faktura.rs"
            target="_blank"
            class="navbar-item has-divider is-desktop-icon-only"
            title="faktura.rs"
          >
            <bIcon icon="help-circle-outline" custom-size="default" />
            <span>faktura.rs</span>
          </a>
          <a
            class="navbar-item is-desktop-icon-only"
            title="Log out"
            onClick={this.logout}
          >
            <bIcon icon="logout" custom-size="default" />
            <span>Log out</span>
          </a>
        </div>
      </div>
    </nav >;
  }
}
