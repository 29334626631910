import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class GrowingNumber extends Vue {
  @Prop({ default: '' }) prefix: string;
  @Prop({ default: '' }) suffix: string;
  @Prop({ default: 0 }) value: number;
  @Prop({ default: 500 }) duration: number;

  newValue: number = 0;
  step: number = 0;

  get newValueFormatted() {
    return (this.newValue < 1000) ? this.newValue : this.newValue.toFixed(2)
  }

  mounted() {
    this.growInit()
  }

  @Watch('value')
  onValueChange () {
    this.growInit()
  }

  growInit() {
    const m = this.value / (this.duration / 25)
    this.grow(m)
  }

  grow(m: any) {
    const v = Math.ceil(this.newValue + m)

    if (v > this.value) {
      this.newValue = this.value
      return;
    }

    this.newValue = v
    setTimeout(() => {
      this.grow(m)
    }, 25)
  }

  render() {
    return <div>
      {this.prefix}{this.newValueFormatted}{this.suffix}
    </div>
  }

}
