import AsideMenuItem from './AsideMenuItem'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    AsideMenuItem
  }
})
export default class AsideMenuList extends Vue {
  @Prop({ default: false }) isSubmenuList: boolean;
  @Prop({ default: [] }) menu: any[];

  menuClick(item: any) {
    this.$emit('menu-click', item)
  }

  render() {
    return <ul class={{ 'menu-list': !this.isSubmenuList }}>
      {
        this.menu.map((item, index) => <aside-menu-item menuClick={this.menuClick} item={item} key={index} />)
      }
    </ul>;
  }
}
