import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class HeroBar extends Vue {
  @Prop({ default: true }) hasRightVisible: boolean;

  render() {
    return <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                <slot />
              </h1>
            </div>
          </div>
          <div vShow={this.hasRightVisible} class="level-right">
            <div class="level-item">
              <slot name="right" />
            </div>
          </div>
        </div>
      </div>
    </section>;
  }
}
