import { Vue, Component } from 'vue-property-decorator'
import { userStore } from '@/store'
import { modifiers as m } from "vue-tsx-support";

@Component
export default class Login extends Vue {
  username: string = ''
  password: string = ''

  login() {
    userStore.login({ username: this.username, password: this.password })
      .then(() => this.$router.push('/'))
      .catch((err: any) => console.log(err))
  }

  render() {
    return <section class="section hero is-fullheight is-error-section">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-two-fifths">
              <div class="card has-card-header-background">
                <header class="card-header">
                  <p class="card-header-title">
                    <span class="icon">
                      <i class="mdi mdi-lock default"></i>
                    </span>
                    <span>faktura.rs</span>
                  </p>
                </header>
                <div class="card-content">
                  <form onSubmit={m.prevent(this.login)}>
                    <bField label="Корисничко име">
                      <bInput
                        type="text"
                        autocomplete="on"
                        name="username"
                        required="required"
                        autofocus="autofocus"
                        icon="account-box"
                        vModel={this.username}
                      />
                    </bField>
                    <bField label="Лозинка">
                      <bInput
                        type="password"
                        autocomplete="on"
                        name="password"
                        required="required"
                        icon="lock"
                        passwordReveal={true}
                        vModel={this.password}
                      />
                    </bField>
                    <bField>
                      <bCheckbox>Запамти ме</bCheckbox>
                    </bField>
                    <hr />
                    <div class="field is-grouped">
                      <div class="control">
                        <button type="submit" class="button is-black" onClick={this.login}>Приступи</button>
                      </div>
                      <div class="control">
                        <a
                          href="#/password-recovery"
                          class="button is-outlined is-black"
                        >Опоравак лозинке?</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot has-text-centered">
        <div class="logo"></div>
      </div>
    </section>
  }
}
