import { Vue, Component, Prop } from 'vue-property-decorator'
import { uiStore, userStore } from '@/store'
const logo = require('@/assets/logo.svg') as string;

@Component
export default class AsideMenu extends Vue {
  @Prop({ default: [] }) menu: any[];
  uiStore = uiStore;

  menuClick() {
    //
  }

  logout() {
    userStore.logout();
  }

  render() {
    return <aside style={{ 'display': uiStore.isAsideVisible ? 'flex' : 'none' }} class="aside is-placed-left is-expanded">
      <div class="aside-logo">
        <img src={logo} alt="logo" />
      </div>
      <div class="menu-container">
        <div class="menu is-menu-main">
          {
            this.menu.map((menuGroup, index) => {
              return (typeof menuGroup === 'string')
                ? <p class="menu-label" key={index}>{menuGroup}</p>
                : <aside-menu-list
                  key={index}
                  menuClick={this.menuClick}
                  menu={menuGroup} />
            })
          }
        </div>
      </div>
      <div class="menu is-menu-bottom">
        <ul class="menu-list">
          <li class="">
            <a onClick={this.logout} class="has-icon is-state-info is-hoverable">
              <span class="icon"><i class="mdi mdi-logout default"></i></span>
              <span class="menu-item-label">Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </aside >;
  }
}
