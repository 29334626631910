import { Vue, Component } from 'vue-property-decorator';
import { modifiers as m } from "vue-tsx-support";
import { uiStore } from '@/store';
import { ISidePanel } from '@/stores/uiModule';

@Component
export default class SidePanel extends Vue {
  component: any;
  data: any;
  title: string;

  isSaving = false;
  unwatch: Function;

  created() {
    this.unwatch = uiStore.$watch(
      (uiStore) => uiStore.sidePanel,
      (newValue: ISidePanel) => {
        this.title = newValue.title;
        this.component = newValue.component;
        this.data = newValue.data;
      }
    );
  }

  beforeDestroy() {
    this.unwatch();
  }

  close() {
    uiStore.closeSidePanel();
  }

  submit() {
    this.isSaving = true
    uiStore.sidePanel
      .action(uiStore.sidePanel.data)
      .finally(() => {
        this.isSaving = false;
        this.close();
      });
  }

  render() {
    const DynamicComponent = this.component;
    return <bSidebar
      vModel={uiStore.isSidePanelOpen}
      right={true}
      fullheight={true}
      overlay={true}
      canCancel={false}
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{this.title}</p>
        <button type="button" class="delete" onClick={() => this.close()} />
      </header>
      <section class="modal-card-body">
        <form id="myform" onSubmit={m.prevent(this.submit)} >
          {uiStore.isSidePanelOpen && <DynamicComponent data={this.data} />}
        </form>
      </section>
      <footer class="modal-card-foot is-justify-content-space-between">
        <bButton type="is-primary" form="myform" native-type="submit" loading={this.isSaving}>
          Сачувај
        </bButton>
        <bButton onClick={() => this.close()}>
          Одустани
        </bButton>
      </footer>
    </bSidebar>;
  }
}
