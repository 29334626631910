import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TitleBar extends Vue {
  @Prop({ default: [] }) titleStack: string[];
  @Prop({ default: () => [] }) actions: Array<{
    icon: string;
    label?: string;
    class?: string;
    callback: () => void;
  }>;
  render() {
    return <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              {
                this.titleStack.map(title => <li>{title}</li>)
              }
            </ul>
          </div>
        </div>
        {
          !!this.actions.length &&
          <div class="level-right">
            {
              this.actions.map((action: any, index: number) => {
                return <div class="level-item">
                  <bButton
                    key={index}
                    type="is-primary"
                    iconLeft={action.icon}
                    onClick={action.callback}
                  >
                    {action.label}
                  </bButton>
                </div>
              })
            }
          </div>
        }
      </div>
    </section >
  }
}
