import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import api from '@/api'
import router from '@/router'

@Module
export class UserModule extends VuexModule {
  userName: string = '';
  userEmail: string = '';
  userAvatar: string = '';
  status: string = '';
  token: string = localStorage.getItem('token') || '';

  get isLoggedIn() {
    return !!this.token;
  }

  @Mutation
  auth_request() {
    this.status = 'loading'
  }

  @Mutation
  auth_success({ token, user }: { token: string, user: any }) {
    this.status = 'success'
    this.token = token
    this.userName = user.ime
    this.userEmail = user.company.email
    this.userAvatar = '/data-sources/avatars/annie-spratt-121576-unsplash.jpg'
    localStorage.setItem('token', token)
  }

  @Mutation
  auth_error({ err }: any) {
    this.status = `Error: ${err}`
  }

  @Mutation
  onLogout() {
    this.status = ''
    this.token = ''
    localStorage.setItem('token', '')
  }

  @Action
  login(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth_request()
      api.post('/user', user)
        .then((resp: any) => {
          const token = resp.token
          const user = resp.user
          // api.defaults.headers.Authorization = token
          this.auth_success({ token, user })
          resolve(resp)
        })
        .catch((err: any) => {
          this.auth_error({ err })
          reject(err)
        })
    })
  }

  @Action
  register(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth_request()
      api.post('/register', user)
        .then(resp => {
          const token = resp.token
          const user = resp.user
          this.auth_success({ token, user })
          resolve(resp)
        })
        .catch(err => {
          this.auth_error({ err })
          reject(err)
        })
    })
  }

  @Action
  logout() {
    return new Promise((resolve) => {
      this.onLogout()
      router.replace('/login');
      resolve(true)
    })
  }

  @Action
  getUserData() {
    return new Promise((resolve, reject) => {
      this.auth_request()
      api.get('/user', {})
        .then(resp => {
          const user = resp.user;
          if (user.id) {
            this.auth_success({ token: this.token, user })
          } else {
            this.logout();
          }
          resolve(resp)
        })
        .catch(err => {
          this.auth_error({ err })
          reject(err)
        })
    })
  }
}
