
import { Vue, Component, Prop } from 'vue-property-decorator'
import { modifiers as m } from "vue-tsx-support";

@Component
export default class CardComponent extends Vue {
  @Prop({ default: null }) title: string;
  @Prop({ default: null }) icon: string;
  @Prop({ default: null }) headerIcon: string;

  headerIconClick() {
    this.$emit('header-icon-click')
  }

  render() {
    return <div class="card">
      {
        this.title &&
        <header class="card-header">
          <p class="card-header-title">
            {this.icon && <bIcon icon={this.icon} custom-size="default" />}
            {this.title}
          </p>
          {
            this.headerIcon &&
            <a href="#" class="card-header-icon" aria-label="more options" onClick={m.prevent(this.headerIconClick)}>
              <bIcon icon={this.headerIcon} custom-size="default" />
            </a>
          }
        </header>
      }
      <div class="card-content">
        {this.$slots.default}
      </div>
    </div >
  }
}
