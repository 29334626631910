import { Vue, Component, Prop } from 'vue-property-decorator'
import CardComponent from '@/components/CardComponent'
import GrowingNumber from '@/components/GrowingNumber'

@Component({
  components: {
    GrowingNumber,
    CardComponent
  }
})
export default class CardWidget extends Vue {
  @Prop({ default: null }) icon: string;
  @Prop({ default: 0 }) number: number;
  @Prop({ default: null }) prefix: string;
  @Prop({ default: null }) suffix: string;
  @Prop({ default: null }) label: string;
  @Prop({ default: null }) type: string;
  @Prop({ default: null }) title: string;

  render() {
    return <cardComponent title={this.title} headerIcon="settings">
      <div class="level is-mobile">
        <div class="level-item">
          <div class="is-widget-label">
            <h3 class="subtitle is-spaced">
              {this.label}
            </h3>
            <h1 class="title">
              <growingNumber value={this.number} prefix={this.prefix} suffix={this.suffix} />
            </h1>
          </div>
        </div>
        {
          this.icon &&
            <div class="level-item has-widget-icon">
              <div class="is-widget-icon">
                <bIcon icon={this.icon} size="is-large" type={this.type} />
              </div>
            </div>
        }
      </div>
    </cardComponent>
  }
}
