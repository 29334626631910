import { Line, mixins as chartMixins } from 'vue-chartjs'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class LineChart extends mixins(Line, chartMixins.reactiveProp) {
  @Prop({ default: null }) chartData!: { datasets?: any[]; labels?: string[] };
  @Prop() extraOptions: any;

  mounted () {
    this.$watch('chartData', (newVal, oldVal) => {
      if (!oldVal) {
        this.renderChart(
          this.chartData,
          this.extraOptions
        )
      }
    }, { immediate: true })
  }
}
