import { Vue, Component, Prop } from 'vue-property-decorator'
import { userStore } from '@/store';

@Component
export default class UserAvatar extends Vue {
  @Prop({ default: null }) avatar: string;

  get newUserAvatar() {
    if (this.avatar) {
      return this.avatar
    }
    if (userStore.userAvatar) {
      return userStore.userAvatar
    }
    let name = 'somename'
    if (userStore.userName) {
      name = userStore.userName.replace(/[^a-z0-9]+/i, '')
    }
    return `https://avatars.dicebear.com/v2/human/${name}.svg?options[mood][]=happy`
  }

  render() {
    return <div class="is-user-avatar">
      <img src={this.newUserAvatar} alt={userStore.userName} />
    </div>;
  }
}
