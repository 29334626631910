import { VuexModule, Module, Mutation } from "vuex-class-modules";

export interface ISidePanel {
  title: string,
  component: any,
  data: any
  action: any
}

@Module
export class UIModule extends VuexModule {
  /* NavBar */
  isNavBarVisible = true;

  /* FooterBar */
  isFooterBarVisible = true;

  /* Aside */
  isAsideVisible = true;
  isAsideMobileExpanded = false;

  /* SidePanel */
  sidePanel: ISidePanel = { title: '', component: null, data: {}, action: null };
  isSidePanelOpen: boolean = false;

  @Mutation
  asideMobileStateToggle(payload?: boolean) {
    const htmlClassName = 'has-aside-mobile-expanded';

    let isShow: boolean;

    if (payload !== null) {
      isShow = !!payload;
    } else {
      isShow = !this.isAsideMobileExpanded;
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName);
    } else {
      document.documentElement.classList.remove(htmlClassName);
    }

    this.isAsideMobileExpanded = isShow;
  }

  @Mutation
  openSidePanel({ title, component, data, action }: ISidePanel) {
    this.sidePanel = {
      title,
      component,
      data: { ...data },
      action,
    }
    this.isSidePanelOpen = true;
  }

  @Mutation
  closeSidePanel() {
    this.isSidePanelOpen = false;
  }

  @Mutation
  toggleFullScreen(payload: any) {
    const htmlClassName = 'has-aside-expanded';

    this.isAsideVisible = payload
    this.isNavBarVisible = payload
    this.isFooterBarVisible = payload

    if (this.isAsideVisible) {
      document.documentElement.classList.add(htmlClassName);
    } else {
      document.documentElement.classList.remove(htmlClassName);
    }

  }
}
