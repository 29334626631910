import moment from 'moment';
import { Vue, Component } from 'vue-property-decorator';
import { uiStore } from '@/store';
const logo = require('@/assets/ing-logo.svg') as string;

@Component
export default class FooterBar extends Vue {
  uiStore = uiStore;

  get year() {
    return moment().year()
  }

  render() {
    return <footer vShow={uiStore.isFooterBarVisible} class="footer">
      <div class="container-fluid">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              &copy; {this.year}, АД Инжењеринг - Чачак
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <div class="logo">
                <a href="https://ing.rs">
                  <img src={logo} alt="ing.rs" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  }
}
