import { Vue, Component, Prop } from 'vue-property-decorator'
import { VNode } from 'vue'
import { splitEvery } from 'rambda';

@Component
export default class Tiles extends Vue {
  @Prop({ default: 5 }) maxPerRow: number;

  renderAncestor (createElement: any, elements: VNode[]) {
    return createElement('div', { attrs: { 'class': 'tile is-ancestor' } }, elements.map((element) => {
      return createElement('div', { attrs: { 'class': 'tile is-parent' } }, [element])
    }))
  }

  render (createElement: any) {
    if (!this.$slots.default) return;
    if (this.$slots.default.length <= this.maxPerRow) {
      return this.renderAncestor(createElement, this.$slots.default)
    } else {
      return createElement('div', { attrs: { 'class': 'is-tiles-wrapper' } }, splitEvery(this.maxPerRow, this.$slots.default).map((group: any) => {
        return this.renderAncestor(createElement, group)
      }))
    }
  }
}
