import NavBar from '@/components/NavBar';
import AsideMenu from '@/components/AsideMenu';
import FooterBar from '@/components/FooterBar';
import SidePanel from '@/components/SidePanel';
import { Vue, Component } from 'vue-property-decorator';
import { userStore, uiStore } from '@/store';
// import api from '@/api'

@Component({
  components: {
    FooterBar,
    AsideMenu,
    SidePanel,
    NavBar
  }
})
export default class Home extends Vue {
  uiStore = uiStore;

  get menu() {
    return [
      'Опште',
      [
        {
          to: '/',
          label: 'Статистика',
          icon: 'desktop-mac'
        }
      ],
      'Алатке',
      [
        {
          to: '/invoices',
          label: 'Фактуре',
          icon: 'library-books'
        },
        {
          to: '/',
          label: 'Дописи',
          icon: 'file-document'
        },
        {
          to: '/',
          label: 'Стање на рачуну',
          icon: 'bank'
        },
        {
          to: '/',
          label: 'Каса',
          icon: 'calculator-variant'
        },
        {
          to: '/',
          label: 'Магацин',
          icon: 'chart-bar-stacked'
        },
        {
          to: '/clients',
          label: 'Клијенти',
          icon: 'account-multiple',
          // updateMark: true
        },
        {
          to: '/exchange',
          label: 'Курсна листа',
          icon: 'elevation-rise'
        },
      ],
      'About',
      [
        {
          to: '/profile',
          label: 'Профил',
          icon: 'account-circle'
        },
        {
          href: 'https://faktura.rs',
          label: 'О нама',
          icon: 'help-circle'
        }
      ]
    ]
  }
  created() {
    /* api.interceptors.response.use(undefined, (err: any) => {
      return new Promise(() => {
        this.$buefy.toast.open({
          message: `Error: ${err.message}`,
          type: 'is-danger'
        })
        if (err.response.status === 401) userStore.logout();
      });
    });
    */
    userStore.getUserData();
  }

  render() {
    return <div id="app">
      {uiStore.isNavBarVisible && <navBar />}
      <asideMenu menu={this.menu} />
      <section>
        <routerView />
      </section>
      <footerBar />
      <sidePanel />
    </div>
  }
}
