/* Styles */
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from '@/router'
// import store from './store'
import { userStore, uiStore } from '@/store'

/* Service Worker */
import '@/registerServiceWorker'

/* Vue. Main component */
import App from '@/App'

/* Vue. Component in recursion */
import AsideMenuList from '@/components/AsideMenuList'

import VueMask from 'v-mask'

/* Collapse all on login page */
router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => !record.meta.isPublic) && !userStore.isLoggedIn) {
    next('/login')
  } else {
    uiStore.toggleFullScreen(!to.meta.isFullScreen);
    next()
  }
})

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  uiStore.asideMobileStateToggle(false)
  uiStore.closeSidePanel()
})

Vue.config.productionTip = false

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList)

Vue.use(Buefy, {
  defaultTabsAnimated: false,
  defaultDialogConfirmText: 'У реду',
  defaultDialogCancelText: 'Одустани',
})

Vue.use(VueMask);

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
