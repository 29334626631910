import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Login from '@/views/Login'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import(/* webpackChunkName: "invoices" */ './views/Invoices')
    },
    {
      path: '/invoice/:id',
      name: 'invoice',
      component: () => import(/* webpackChunkName: "invoices" */ './views/Invoice')
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import(/* webpackChunkName: "tables" */ './views/Clients')
    },
    {
      path: '/exchange',
      name: 'exchange',
      component: () => import(/* webpackChunkName: "exchange" */ './views/Exchange')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile')
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { 
        isPublic: true,
        isFullScreen: true
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
