import { userStore } from '@/store';

export default class {
  interceptors: {
    request: () => Promise<any>;
    response: () => Promise<any>;
  }

  static get(url: string, params: any = ''): Promise<any> {
    return this.fetchData(url + '?' + new URLSearchParams(params).toString(), 'GET');
  }

  static post(url: string, data: any): Promise<any> {
    return this.fetchData(url, 'POST', data);
  }

  static delete(url: string): Promise<any> {
    return this.fetchData(url, 'DELETE');
  }

  private static async fetchData(url = '', method = 'POST', data = {}): Promise<any> {
    const baseURL = (process.env.NODE_ENV === 'production') ? 'https://pro.faktura.rs' : 'http://localhost:3000';
    // Default options are marked with *
    const response = await fetch(baseURL + url, {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') || '',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: method !== 'GET' ? JSON.stringify(data) : null
    });
    if (!response.ok) {
      if (response.status === 401) userStore.logout();
      throw Error(response.statusText);
    }
    return response.json();
  }
}
