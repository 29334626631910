import { Vue, Component, Prop } from 'vue-property-decorator'
import UserAvatar from '@/components/UserAvatar';
import { userStore } from '@/store';

@Component
export default class NavBarMenu extends Vue {
  @Prop({ default: false }) isHoverable: boolean;
  isDropdownActive = false;

  get toggleDropdownIcon() {
    return this.isDropdownActive ? 'chevron-up' : 'chevron-down'
  }

  created() {
    window.addEventListener('click', this.forceClose)
  }

  beforeDestroy() {
    window.removeEventListener('click', this.forceClose)
  }

  toggle() {
    if (!this.isHoverable) {
      this.isDropdownActive = !this.isDropdownActive
    }
  }

  forceClose(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isDropdownActive = false
    }
  }

  render() {
    return <div onClick={this.toggle}
      class={{ 'navbar-item has-dropdown has-dropdown-with-icons': true, 'is-hoverable': this.isHoverable, 'is-active': this.isDropdownActive }}>
      <a class="navbar-link is-arrowless has-divider has-user-avatar">
        <UserAvatar />
        <div class="is-user-name">
          <span>{userStore.userName}</span>
        </div>
        <bIcon icon={this.toggleDropdownIcon} custom-size="default" />
      </a>
      <div class="navbar-dropdown">
        <a class="navbar-item">
          <bIcon icon="account" custom-size="default" />
          <span>My Profile</span>
        </a>
        <a class="navbar-item">
          <bIcon icon="settings" custom-size="default" />
          <span>Settings</span>
        </a>
        <a class="navbar-item">
          <bIcon icon="email" custom-size="default" />
          <span>Messages</span>
        </a>
        <hr class="navbar-divider" />
        <a class="navbar-item">
          <bIcon icon="logout" custom-size="default" />
          <span>Log Out</span>
        </a>
      </div>
    </div>;
  }
}
